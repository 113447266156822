import React from 'react';
import { Link } from 'react-router-dom';
import Typewriter from "typewriter-effect";

const Cta = () => {
  const messages = [
    "True security means protecting people, not just systems",
    "When systems fail, families go hungry",
    "Every minute of downtime costs someone their peace of mind",
    "You've built more than a business - you've built futures",
    "Security isn't just IT - it's protecting livelihoods",
    "Can you sure your providers care as much as you do?",
    "When was the last time big tech put your interests first?",
    "Your business deserves more than being just another ticket number",
    "Your life's work deserves dedicated guardians",
    "Stop trusting others with your company's future",
    "Take control of your digital legacy today",
  ];

  return (
    <div className="w-full text-white cta">
      <div className="max-w-7xl mx-auto py-16 px-8">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-16">
          <div className="lg:col-span-8 pl-4">
            <div style={{ height: '4rem' }} className="relative">
              <div className="absolute inset-0">
                <div className='text-4xl md:text-3xl font-light'>
                  <Typewriter
                    options={{
                      strings: messages,
                      autoStart: true,
                      loop: true,
                      deleteSpeed: 70,
                    }}
                  />
                </div>
              </div>
            </div>
            <p className="text-xl md:text-3xl mt-4">
              Take the first step towards true digital independence <span className='font-black'>now</span>
            </p>
          </div>
          <div className="lg:col-span-4 flex items-start pt-4">
            <Link to="/get-demo"
              className="bg-transparent border hover:bg-blue-900 hover:border-blue-800 text-white justify-center text-center rounded-lg px-10 py-3 flex items-center group whitespace-nowrap">
              Own Your IT
              <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cta;