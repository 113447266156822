import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import { useForm, ValidationError } from '@formspree/react';
import Notiflix from 'notiflix';

const DemoProduct = () => {
    useDocTitle("SamSol | Samaritan Solutions - IT Doesn't have to be hard");

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        demoProducts: []
    });

    const [state, handleSubmit] = useForm("mwpkonol");

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData(prev => ({
                ...prev,
                demoProducts: checked 
                    ? [...prev.demoProducts, value]
                    : prev.demoProducts.filter(product => product !== value)
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const clearForm = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            demoProducts: []
        });
    };

    if (state.succeeded) {
        clearForm();
        Notiflix.Report.success(
            'Got it!',
            'Thanks for reaching out. We\'ll get back to you within one business day to schedule your session.',
            'Sounds good',
            () => {
                window.location.href = "/";
            }
        );
    }

    const demoOptions = [
        {
            id: 'evaluation',
            value: '1hr_it_systems_evaluation',
            label: '1-Hour Free IT Systems Review',
            description: "Let's look at your current setup and discuss where you want to go. We'll share our honest thoughts and potential next steps - no pressure, just straight talk.",
            recommended: true
        },
        {
            id: 'network',
            value: 'network_visualization',
            label: 'Network Visualization Demo',
            description: "See your WiFi network in real 3D space. Walk through your space and visualize signal strengths (we can create video), dead zones, and coverage gaps in mixed reality. It's something you just have to see!",
        },
        {
            id: 'products',
            value: 'product_demonstration',
            label: 'Security & Backup Tools Demo',
            description: "See how we use enterprise-grade tools like Wazuh (security), Duplicati (backups), and VPNs to keep your business safe. We'll show you exactly how everything works."
        },
        {
            id: 'automation',
            value: 'it_automation',
            label: 'AI & Automation Solutions Demo',
            description: "Discover how to make technology work for you. From custom AI assistants to automated workflows - we'll show you practical ways to save time and reduce stress."
        }
    ];

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id="demo" className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
                    <form onSubmit={handleSubmit} className="max-w-4xl mx-auto">
                        <div className="w-full bg-white p-8 my-4 md:px-12 rounded-2xl shadow-2xl">
                            <div className="flex flex-col gap-2 mb-8">
                                <h1 className="font-bold text-blue-900 uppercase text-4xl">
                                    Let's Make IT Simple
                                </h1>
                                <p className="text-gray-600 text-lg">
                                    Choose what interests you, and we'll show you exactly how it can work for your business. 
                                    No tech jargon, no sales pitch - just practical solutions you can understand and control.
                                </p>
                            </div>

                            {/* Demo Options */}
                            <div className="space-y-4 mb-8">
                                {demoOptions.map(option => (
                                    <div key={option.id} 
                                         className={`flex items-start p-4 border rounded-lg hover:bg-gray-50 transition-colors ${
                                             formData.demoProducts.includes(option.value) ? 'border-blue-900 bg-blue-50' : 'border-gray-200'
                                         }`}
                                    >
                                        <input
                                            id={option.id}
                                            type="checkbox"
                                            className="h-4 w-4 mt-1.5 text-blue-900 border-gray-300 rounded focus:ring-blue-900"
                                            value={option.value}
                                            checked={formData.demoProducts.includes(option.value)}
                                            onChange={handleChange}
                                            name="demoProducts"
                                        />
                                        <label htmlFor={option.id} className="ml-3 flex flex-col cursor-pointer">
                                            <span className="text-lg font-medium text-gray-900">
                                                {option.label}
                                            </span>
                                            <span className="text-sm text-gray-600 mt-1">
                                                {option.description}
                                            </span>
                                            {option.recommended && (
                                                <span className="text-sm text-blue-900 mt-2 font-medium inline-flex items-center">
                                                    ★ Recommended first step - No cost, no obligations
                                                </span>
                                            )}
                                        </label>
                                    </div>
                                ))}
                            </div>

                            {/* Contact Section */}
                            <div className="border-t pt-6 mt-8">
                                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                                    How can we reach you?
                                </h2>
                                
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    {[
                                        {name: 'firstName', placeholder: 'First Name', type: 'text'},
                                        {name: 'lastName', placeholder: 'Last Name', type: 'text'},
                                        {name: 'email', placeholder: 'Email', type: 'email'},
                                        {name: 'phone', placeholder: 'Phone', type: 'tel'}
                                    ].map((field) => (
                                        <div key={field.name}>
                                            <input
                                                name={field.name}
                                                className="w-full bg-gray-50 text-gray-900 p-3 rounded-lg border border-gray-200 focus:outline-none focus:border-blue-900 focus:ring-1 focus:ring-blue-900 transition-colors"
                                                type={field.type}
                                                placeholder={`${field.placeholder}*`}
                                                value={formData[field.name]}
                                                onChange={handleChange}
                                            />
                                            <ValidationError prefix={field.name} field={field.name} errors={state.errors} />
                                        </div>
                                    ))}
                                </div>

                                <div className="mt-6">
                                    <textarea
                                        name="message"
                                        placeholder="Tell us about your current setup or any specific challenges you're facing. The more we know, the better we can help!"
                                        className="w-full h-32 bg-gray-50 text-gray-900 p-3 rounded-lg border border-gray-200 focus:outline-none focus:border-blue-900 focus:ring-1 focus:ring-blue-900 transition-colors"
                                        value={formData.message}
                                        onChange={handleChange}
                                    ></textarea>
                                    <ValidationError prefix="Message" field="message" errors={state.errors} />
                                </div>

                                <div className="mt-6">
                                    <button
                                        type="submit"
                                        disabled={state.submitting}
                                        className="w-full md:w-auto px-8 py-3 text-sm font-bold tracking-wide text-white bg-blue-900 rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-offset-2 transition-colors disabled:opacity-50"
                                    >
                                        {state.submitting ? "Sending..." : "Let's Get Started"}
                                    </button>
                                    <p className="mt-2 text-sm text-gray-600">
                                        We'll respond within one business day to schedule your session
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default DemoProduct;