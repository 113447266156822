import React from 'react';
import { Link } from 'react-router-dom';
import { Network, Database, Shield, Cpu } from 'lucide-react';

const Portfolio = () => {
    const services = [
        {
            title: "Network and Endpoint Management",
            icon: Network,
            tagline: "Secure and streamline your entire network infrastructure - asset management, patching, with a zero trust solution.",
            description: "Our comprehensive Network and Endpoint Management service keeps your infrastructure secure and up-to-date. With remote monitoring, automated updates, and endpoint security, we ensure your devices, from desktops to mobile, operate seamlessly and securely. Perfect for SMEs and institutions looking to protect their assets and reduce downtime.",
            isPopular: true
        },
        {
            title: "Data Sovereignty Solutions",
            icon: Database,
            tagline: "Take back control of your data with secure, self-hosted, on-premise alternatives to the cloud.",
            description: "Take control of your data with secure, self-hosted solutions tailored to your organization's needs. From Proxmox virtualization to cloud alternatives, we provide platforms that put data sovereignty back in your hands, empowering you with full ownership, robust backups, and privacy-focused storage for critical information.",
            isPopular: false
        },        
        {
            title: "Intelligent IT Automation and Support",
            icon: Cpu,
            tagline: "Let automation handle the routine while you focus on what matters most.",
            description: "Simplify IT with automation and expert support designed for efficiency. From automated patching to custom workflows, our solutions help you streamline repetitive tasks, maintain peak performance, and free up time for strategic growth. Get support whenever you need it, with local expertise and dedicated help.",
            isPopular: false
        },
        {
            title: "Security and Monitoring",
            icon: Shield,
            tagline: "Comprehensive security solutions that protect your people, property, and peace of mind.",
            description: "Our Security services offer top-tier surveillance solutions, access control, and real-time monitoring tailored to educational institutions, businesses, and campuses. With seamless integration and advanced AI capabilities, ensure a safe, secure environment for staff, students, and visitors.",
            isPopular: false
        },
    ];

    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Products</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        {services.map((service, index) => (
                            <div 
                                key={index}
                                className="bg-gradient-to-br from-white to-blue-50
                                    relative overflow-hidden text-gray-700 
                                    transition-all duration-500 ease-in-out
                                    hover:scale-105 rounded-lg shadow-2xl p-3 
                                    hover:shadow-blue-200/50"
                                data-aos="fade-up"
                                data-aos-delay={index * 100}
                            >
                                {service.isPopular && (
                                    <div className="absolute top-4 right-4">
                                        <span className="bg-blue-600 text-white text-xs px-3 py-1 rounded-full">
                                            Popular Choice
                                        </span>
                                    </div>
                                )}
                                
                                <div className="absolute top-0 left-0 w-1 h-full bg-blue-600 opacity-75" />
                                
                                <div className="m-2 text-justify text-sm">
                                    <div className="flex justify-center mb-4">
                                        <service.icon className="w-12 h-12 text-blue-600" />
                                    </div>
                                    
                                    <h4 className="font-bold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                                        {service.title}
                                    </h4>
                                    
                                    <p className="text-md font-medium leading-5 h-auto md:h-12 text-center text-blue-900 mb-4">
                                        {service.tagline}
                                    </p>
                                    
                                    <div className="border-t border-gray-200 my-4" />
                                    
                                    <p className="text-md font-medium leading-5 h-auto md:h-48 mt-4">
                                        {service.description}
                                    </p>
                                    
                                    <div className="flex justify-center mt-6">
                                        <Link 
                                            to="/get-demo" 
                                            className="text-white bg-blue-900 
                                                inline-flex items-center justify-center 
                                                w-full px-6 py-3 text-lg
                                                rounded-xl transform transition-all
                                                hover:bg-blue-800 hover:shadow-lg
                                                hover:shadow-blue-200/50
                                                active:scale-95"
                                        >
                                            Schedule Demo
                                            <svg className="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Portfolio;