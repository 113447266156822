import React from 'react';
import ngh from '../images/clients/ngh.png';
import whittlers from '../images/clients/whittlers.png';
import Del from '../images/clients/Del.png';
import { MapPin, Calendar } from 'lucide-react';

const clientImage = {
    width: '100%',
    maxWidth: '380px',
    maxHeight: '280px',
    objectFit: 'contain',
    transition: 'all 0.4s ease',
    display: 'block',
    margin: '0 auto'
};

const Clients = () => {
    const clients = [
        {
            img: ngh,
            alt: "Nancy's Green House",
            url: "https://nancysgreenhouse.com",
            location: "Gananoque, Ontario, Canada",
            since: "2022"
        },
        {
            img: whittlers,
            alt: "Whittlers Workshop",
            url: "http://web.archive.org/web/20071015213142/http://whittlersworkshop.com/",
            location: "Waldport, Oregon, USA",
            tagline: "Where the Forest Meets the Sea",
            since: "2019"
        },
        {
            img: Del,
            alt: "Del Monsoon",
            url: "https://delmonsoon.com",
            location: "Charlotte, North Carolina, USA",
            since: "2021"
        }
    ];

    return (
        <div className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
                        Featured Clients
                    </h2>
                    <div className="flex justify-center">
                        <div className="w-24 border-b border-blue-900"></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        Trusted by businesses across North America
                    </h2>
                </div>
               
                <div className="px-4 py-8" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-12 max-w-6xl mx-auto">
                        {clients.map((client, index) => (
                            <div key={index} className="flex flex-col items-center group">
                                <div className="w-full flex flex-col items-center">
                                    <a 
                                        href={client.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="w-full block text-center mb-4 relative"
                                        aria-label={`Visit ${client.alt} website`}
                                    >
                                        <img
                                            src={client.img}
                                            alt={client.alt}
                                            style={clientImage}
                                            loading="lazy"
                                            className="transform transition-all duration-300 group-hover:scale-105"
                                        />
                                    </a>
                                    
                                    <div className="w-full flex flex-col items-center space-y-1">
                                        <div className="flex items-center gap-2 text-sm text-blue-800">
                                            <Calendar size={14} className="text-blue-600" />
                                            <span>Partner since {client.since}</span>
                                        </div>
                                        <div className="flex items-center gap-2 text-sm text-blue-800">
                                            <MapPin size={14} className="text-blue-600" />
                                            <span>{client.location}</span>
                                        </div>
                                    </div>
                                </div>
                                {client.tagline && (
                                    <p className="text-center text-gray-600 mt-3 italic text-sm">
                                        {client.tagline}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Clients;