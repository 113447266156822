import React, { useState, useEffect } from 'react';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import Typewriter from "typewriter-effect";
import { Phone } from 'lucide-react';

const NavBar = () => {
  const [top, setTop] = useState(!window.scrollY);
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollPosition = window.pageYOffset;
      setTop(scrollPosition <= 10);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <header className="fixed top-0 w-full z-30">
      <div className={`transform transition-all duration-300 ease-in-out ${!top ? 'bg-white shadow-lg' : 'bg-white/90'}`}>
        {/* CTA Section */}
        <div className="w-full bg-blue-900 text-white">
          <div className="px-4 py-2 lg:py-3">
            <div className="flex flex-col lg:grid lg:grid-cols-12 gap-2 lg:gap-4">
              {/* Typewriter Section - Now first on mobile */}
              <div className="lg:col-span-7 w-full text-center lg:text-left order-1 lg:order-none">
                <div className="h-8 lg:h-16 flex items-center justify-center lg:justify-start">
                  <div className="text-sm lg:text-2xl font-light">
                    <Typewriter
                      options={{
                        strings: [
                          "Can you be sure your providers care as much as you do?",
                          "True security means protecting people, not just systems",
                          "When systems fail, families go hungry",
                          "Every minute of downtime costs someone their peace of mind",
                          "You've built more than a business - you're building futures",
                          "Security isn't just IT - it's protecting livelihoods",
                          "When was the last time big tech put your interests first?",
                          "Your business deserves more than being just another ticket number",
                          "Your life's work deserves dedicated guardians",
                          "Stop trusting others with your company's future",
                          "Take control of your digital legacy today",
                        ],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 30,
                        delay: 30,
                        typeSpeed: 30,
                        delaySpeed: 1700
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Contact & CTA Buttons - Now second on mobile */}
              <div className="lg:col-span-5 w-full flex items-center justify-end space-x-4 order-2 lg:order-none mt-2 lg:mt-0">
                <a 
                  href="tel:5412836104" 
                  className="inline-flex items-center text-white hover:text-blue-100 transition-colors duration-300"
                >
                  <Phone className="w-4 h-4 lg:w-5 lg:h-5 mr-1.5 lg:mr-2" />
                  <span className="text-sm lg:text-lg font-medium">(541) 283-6104</span>
                </a>
                <Link
                  to="/get-demo"
                  className="inline-flex items-center px-3 lg:px-6 py-1 lg:py-2 border border-white text-sm lg:text-base font-medium rounded-lg text-white hover:bg-white hover:text-blue-900 transition-colors duration-300 group"
                >
                  Request a Demo
                  <svg
                    className="ml-1.5 lg:ml-2 -mr-0.5 w-3 h-3 lg:w-4 lg:h-4 group-hover:translate-x-2 transition-transform duration-300"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Bar */}
        <nav className="px-4 bg-white">
          <div className="flex justify-between items-center py-2 lg:py-4">
            <HashLink smooth to="/#hero">
              <h1 className="font-extrabold text-lg sm:text-2xl lg:text-3xl text-blue-900">
                Samaritan Solutions
              </h1>
            </HashLink>

            <div className="flex items-center">
              <button
                className="p-1.5 rounded-lg lg:hidden text-blue-900"
                onClick={() => setisOpen(!isOpen)}
              >
                <svg 
                  className="h-5 w-5" 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 24 24"
                >
                  {isOpen ? (
                    <path 
                      fillRule="evenodd" 
                      clipRule="evenodd" 
                      d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" 
                    />
                  ) : (
                    <path 
                      fillRule="evenodd" 
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" 
                    />
                  )}
                </svg>
              </button>

              <div className="hidden lg:flex lg:items-center space-x-8">
                <NavLinks />
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <div className={`lg:hidden transition-all duration-300 ease-in-out ${
            isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          }`}>
            <div className="px-4 pt-2 pb-3 space-y-1 bg-white border-t">
              <NavLinks />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default NavBar;